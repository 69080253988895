export const yesNoOptions = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];

export const shared = {
  auNational: "AU National",
  selectAllSubregions: "Select all Subregions",
  allRegions: "All Regions",
  allTumorStreams: "All Tumour Streams",
};

export const informationContent = {
  heading: "Information",
  fields: {
    tumourStream: "Tumour Stream*",
    siteFilter: "Site Filter*",
    doseFilter: "Dose Filter*",
    techniqueFilter: "Technique Filter*",
    name: "Template Name*",
    treatmentSites: "Treatment Site Groups",
    siteGroupSites: "Site Group Sites",
    Treating_Department: "Treating Departments",
    site: "Site",
    subregions: "Subregions",
    mrLinac: "MRL Treatment",
    isPlanningAutomationEnabled: "Planning Automation Enabled",
    evidenceId: "Evidence ID*",
    evidenceVersion: "Evidence Version",
    evidenceLink: "Link to Evidence URL",
    numberOfCycles: "Number of Cycles",
    continuous: "Continuous Cycle",
    daysPerCycle: "Days per Cycle",
    excludeFromSigmaRecommendations: "Exclude From Sigma Recommendations",
    hasNegativeDays: "Negative Days",
    negativeDays: "",
  },
  numberFields: ["numberOfCycles", "daysPerCycle", "evidenceVersion"],
  numberListFields: ["negativeDays"],
};

export const simulationContent = {
  heading: "Simulation",
  fields: {
    CT_Simulation: "CT Simulation",
    RO_Required: "RO Required",
    Patient_Position: "Patient Position",
    Position_Other: "Position Setup",
    Extent_Of_CT: "Extent of CT",
    Extent_Defined: "Extent Defined",
    Immobilisation: "Immobilisation",
    Mask: "Mask",
    Stomach: "Stomach",
    Compression: "Compression",
    Bladder: "Bladder",
    Bowel_Prep: "Bowel Prep",
    Gold_Seeds: "Gold Seeds",
    Vaginal_Tampon: "Vaginal Tampon",
    Spacer_Gel: "Spacer Gel",
    Rectal_Displacement_Device: "Rectal Displacement Device",
    Tongue_Position: "Tongue Position",
    Mouthpiece: "Mouthpiece",
    Gating: "Gating",
    Four_DCT: "4DCT",
    DIBH: "DIBH",
    Breath_Hold: "Breath Hold",
    Bolus: "Bolus",
    Is_3d_Bolus: "3D Bolus",
    Bolus_Location_Type: "Bolus Location",
    Bolus_Location: "Bolus Location Specify",
    Bolus_Thickness: "Bolus Thickness",
    Bolus_Thickness_Custom: "Custom Thickness",
    Bolus_Frequency: "Bolus Frequency",
    Extra_CT_Markers: "Additional Markers",
    Contrast_Required: "Contrast Required",
    Additional_Sim_Info: "Additional Sim Info",
  },
  textFields: [
    "Position_Other",
    "Extent_Defined",
    "Tongue_Position",
    "Is_3d_Bolus",
    "Bolus_Location_Type",
    "Bolus_Location",
    "Bolus_Thickness_Custom",
    "Extra_CT_Markers",
    "Additional_Sim_Info",
  ],
};

export const prescriptionContent = {
  heading: "Prescription",
  fields: {
    Phase: "Phase",
    Sequence: "Sequence",
    Technique: "Technique",
    Modality: "Modality",
    Dose: "Dose(Gy)",
    Fractions: "Fractions(#)",
    Fraction_Dose: "Fraction Dose(Gy/#)",
    Dose_To: "Dose To",
    Custom_Dose_To: "Dose Clarification",
    Dose_Frequency: "Frequency",
    Custom_Dose_Frequency: "Custom Frequency",
    Imaging: "Imaging",
    Imaging_Technique: "Imaging Technique",
    Bolus: "Bolus",
    Bolus_Location: "Bolus Location",
    Bolus_Thickness: "Bolus Thickness",
    Bolus_Thickness_Custom: "Custom Thickness",
    Bolus_Frequency: "Bolus Frequency",
    Treatment_Break: "Break",
    Treatment_Break_Duration: "Minimum break (weeks)",
    Treatment_Break_Fraction: "Commence break after fraction",
    Additional_Prescription_Info: "Additional Prescription Info",
  },
  textFields: [
    "Dose",
    "Fractions",
    "Custom_Dose_To",
    "Custom_Dose_Frequency",
    "Bolus_Location",
    "Bolus_Thickness_Custom",
    "Treatment_Break_Duration",
    "Treatment_Break_Fraction",
    "Additional_Prescription_Info",
  ],
  booleanFields: [
    "Bolus",
    "Is_Multidose", // Just used for peer review
  ],
};

export const volumingContent = {
  heading: "Voluming",
  fields: {
    GTV: "GTV",
    CTV: "CTV",
    PTV: "PTV",
    OAR: "OAR",
    Additional_OAR_Info: "Additional OAR Information",
  },
};

export const peerReviewContent = {
  heading: "Peer Review",
  fields: {
    QCL: "QCL",
    Credentials: "Credentials",
    Violations: "Violations",
    alwaysTrigger: "Always Trigger",
    Enabled_Subregions: "Enabled Subregions",
  },
};

export const cycleContent = {
  heading: "Drug Orders",
  fields: {},
  textFields: [],
};

export const doseName = (length) => {
  switch (length) {
    case 1:
      return [""];
    case 2:
      return ["High", "Low"];
    case 3:
      return ["High", "Mid", "Low"];
    case 4:
      return ["High", "Mid", "Mid", "Low"];
    default:
      return [""];
  }
};

export const tvName = (length) => {
  switch (length) {
    case 1:
      return [""];
    case 2:
      return ["High", "Low"];
    case 3:
      return ["High", "Intermediate", "Low"];
    case 4:
      return ["High", "Intermediate 1", "Intermediate 2", "Low"];
    default:
      return [""];
  }
};

export const sortSites = (sites) =>
  sites.slice().sort((a, b) => parseInt(a.id, 10) - parseInt(b.id, 10));

export const TEST = "test";
export const APPROVE = "approve";
export const DRAFT = "draft";
export const ARCHIVE = "archive";

export const beingEdited = (isActive, status) => isActive && status !== APPROVE;

export const getNiceStatus = (status) => {
  if (status === APPROVE) return "Approved";
  if (status === TEST) return "Testing in Progress";
  if (status === ARCHIVE) return "Archived";
  return "Editing in Progress";
};

export const getNationalName = (region) => `${region} National`;

export const isAu = (regions) =>
  regions.map((region) => region.name).includes("AU");
export const isUS = (regions) =>
  regions.map((region) => region.name).includes("US");

export const DRUG_CONTENT = {
  fields: {
    genericName: "Generic Name*",
    brandName: "Brand Name*",
    route: "Route*",
    form: "Form*",
    type: "Type",
    units: "Units*",
    drugCategory: "Drug Category*",
    singleDoseLimit: "Single Dose Limit",
    lifetimeDoseLimit: "Lifetime Dose Limit",
    concentration: "Concentration",
    solution: "Solution",
    strength: "Strength",
    roundToNearest: "Round to Nearest",
    duration: "Duration",
    administrationInstructions: "Administration Instructions",
    patientInstructions: "Patient Instructions",
    createdAt: "Last Modified",
  },
};

export const CATEGORY_OPTIONS = [
  { label: "Treatment", value: "Treatment" },
  { label: "Supporting", value: "Supporting" },
];

export const DRUG_ORDER_CONTENT = {
  fields: {
    name: "Name*",
    genericName: "Generic Name",
    brandName: "Brand Name",
    route: "Route*",
    type: "Type*",
    dose: "Dose*",
    units: "Units*",
    doseBands: "Dose Banding",
    doseBasis: "Dose Basis*",
    roundToNearest: "Round to Nearest",
    solution: "Solution",
    frequency: "Frequency",
    prn: "PRN",
    duration: "Duration",
    administrationInstructions: "Administration Instructions",
    patientInstructions: "Patient Instructions",
    singleDoseLimit: "Single Dose Limit",
    lifetimeDoseLimit: "Lifetime Dose Limit",
  },
};

export const formatEvidence = (evidenceId, evidenceVersion) => {
  const version = evidenceVersion ? `v${evidenceVersion}` : "";
  return `${evidenceId || ""} ${version}`;
};

export const cycles = (continuous, numberOfCycles) =>
  continuous ? "Continuous" : numberOfCycles;

export const DEFAULT_SEARCH_ROWS = 100;

export const TEMPLATE_PAGE = "Template Page";
export const INFORMATION = "Information";
export const SIMULATION = "Simulation";
export const PRESCRIPTION = "Prescription";
export const VOLUMING = "Voluming";
export const PEER_REVIEW = "Peer Review";
export const BULK_EDIT_REVIEW = "Review & Submit";
export const CYCLE = "Drug Orders";

export const basePages = [
  TEMPLATE_PAGE,
  INFORMATION,
  SIMULATION,
  PRESCRIPTION,
  VOLUMING,
];
export const moPages = [TEMPLATE_PAGE, INFORMATION, CYCLE];
export const bulkEditPages = [VOLUMING, BULK_EDIT_REVIEW];
